import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import posthog from 'posthog-js';

if (environment.production) {
  enableProdMode();

  posthog.init(environment.posthog.key, {
    api_host: environment.posthog.host,
    person_profiles: 'identified_only' // or 'always' to create profiles for anonymous users as well
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
